import axios from "axios";
import config from "../../public/config.json"

const ApiOmniwallet = axios.create({
  baseURL: config.api.url,
  withCredentials: true,
  timeout: 30000,
  headers: {
    Authorization: `Bearer ${localStorage.getItem("omniwalletTemporaryToken")}`
  }
});

ApiOmniwallet.interceptors.request.use((axiosConfig) => {
  if (config.api.dev_mode === false) {
    axiosConfig.headers.Authorization = `Bearer ${localStorage.getItem("omniwalletTemporaryToken")}`;
  } else {
    axiosConfig.headers.Authorization = `Bearer ${config.api.dev_token}`;
  }
  

  return axiosConfig
}, (error) => {
  // TODO: Do something on error?

  return Promise.reject(error)
})

export default ApiOmniwallet;
